import React from 'react';

const CloudDisconnected = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.27 71.5">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    d="M96.41,71.5H19.59c-6.29,0-9.91-2.59-11.85-4.76a1.5,1.5,0,0,1,2.24-2c2.22,2.49,5.45,3.76,9.61,3.76H96.41c6.41,0,17.86-6.09,17.86-18.23,0-8.28-6-19.94-19.26-19.94a1.5,1.5,0,0,1-1.5-1.5,1.53,1.53,0,0,1,0-.3,18,18,0,0,0,.34-3.27A22.19,22.19,0,0,0,71.81,3C56.44,2.94,49.25,14.48,47.42,21.32a1.51,1.51,0,0,1-1.84,1.06h0a24.31,24.31,0,0,0-6.4-.86h0a19.65,19.65,0,0,0-15.33,7,1.5,1.5,0,1,1-2.32-1.91,22.67,22.67,0,0,1,17.65-8.07h0a27.33,27.33,0,0,1,5.77.62C47.55,11.42,55.6,0,71.66,0h.16A25.2,25.2,0,0,1,96.88,25.29c0,.71,0,1.42-.13,2.12,9,.83,20.52,8,20.52,22.86C117.27,64.41,103.9,71.5,96.41,71.5Z"
                />
                <path
                    d="M13,46.11l8.33-8.33a1.5,1.5,0,0,0-2.12-2.12L10.89,44,2.56,35.66A1.5,1.5,0,0,0,.44,37.78l8.33,8.33L.44,54.44a1.5,1.5,0,0,0,2.12,2.12l8.33-8.33,8.32,8.33a1.5,1.5,0,0,0,2.13-2.12Z"
                />
            </g>
        </g>
    </svg>
);

export default CloudDisconnected;
