import { Button } from '@adobe/react-spectrum';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './Styles.css';

interface Props {
    icon: React.ReactNode;
    title: string;
    descPrimary: string;
    descSecondary?: string;
    refresh?: boolean;
    children?: React.ReactNode;
}

const Error: React.FC<Props> = ({ icon, title, descPrimary, descSecondary, refresh = false, children }) => {
    const history = useHistory();

    const handleRefresh = () => {
        history.goBack();
    };

    return (
        <div className="error-wrapper" id={'error'}>
            {icon}
            <span className="error-title">{title}</span>
            <span className="error-desc">{descPrimary}</span>
            {descSecondary && <span className="error-desc">{descSecondary}</span>}
            <div className="error-content-wrapper">
                {refresh && (
                    <Button variant="secondary" onPress={() => handleRefresh()}>
                        Refresh
                    </Button>
                )}
                {children && children}
            </div>
        </div>
    );
};

export default Error;
