import React, { lazy, Suspense } from 'react';
import { Provider, defaultTheme, lightTheme } from '@adobe/react-spectrum';
import V2Provider from '@react/react-spectrum/Provider';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

//Tagger+Curator
const TagManager = lazy(() => import('./pages/curate/TagManager'));
const StartConfirmation = lazy(() => import('./pages/curate/StartConfirmation'));
const FinishTaggerConfirmation = lazy(() => import('./pages/curate/FinishConfirmation'));

//Admin
const ContentManager = lazy(() => import('./pages/admin/ContentManager'));

//Error Pages
const NotAuthorized = lazy(() => import('./pages/errors/Not-Authorized'));
const NotFound = lazy(() => import('./pages/errors/Not-Found'));
const SomethingWentWrong = lazy(() => import('./pages/errors/Something-Went-Wrong'));
const PageRenderError = lazy(() => import('./pages/errors/PageRenderError'));

//IMS
const ImsClient = lazy(() => import('./pages/ImsClient'));

//Login
import LoginRedirect from './components/ims/LoginRedirect';

import IMS from './ims';
import { getAppURIPath, isDarkMode, isStageEnvironment } from './utils';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/components/app.css';

function App() {
    const PrivateRoute = ({ component: Component, path, ...rest }: any) => {
        return (
            <Route
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                path={path}
                render={(props) =>
                    new IMS().isLoggedIn() ? (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{ pathname: '/cc/oxford/login', state: { from: props.location, originPath: path } }}
                        />
                    )
                }
            />
        );
    };

    const NonPrivateRoute = ({ component: Component, path, ...rest }: any) => {
        return (
            <Route
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                path={path}
                render={(props) => <Component {...props} />}
            />
        );
    };

    return (
        <Router>
            <Provider theme={isDarkMode() ? lightTheme : defaultTheme} colorScheme="light">
                <V2Provider>
                    <ErrorBoundary>
                        <main>
                            <div>
                                <Suspense fallback={<div></div>}>
                                    <Switch>
                                        <NonPrivateRoute path='/taas' exact component={() => <div>hello world</div>} />

                                        <Route path={getAppURIPath('login')} exact component={LoginRedirect} />

                                        {/* Curator Routes */}

                                        <PrivateRoute path="/cc/oxford" exact component={StartConfirmation} />
                                        <PrivateRoute
                                            path={`${getAppURIPath('curate')}`}
                                            exact
                                            component={TagManager}
                                        />
                                        <PrivateRoute
                                            path={getAppURIPath('curate/confirm')}
                                            exact
                                            component={FinishTaggerConfirmation}
                                        />

                                        {/* Admin Routes */}
                                        <PrivateRoute
                                            path={getAppURIPath('content-manager')}
                                            exact
                                            component={ContentManager}
                                        />

                                        {/* Error Pages */}
                                        <PrivateRoute
                                            path={getAppURIPath('something-went-wrong')}
                                            exact
                                            component={SomethingWentWrong}
                                        />
                                        <PrivateRoute path={getAppURIPath('not-found')} exact component={NotFound} />
                                        <PrivateRoute
                                            path={getAppURIPath('not-authorized')}
                                            exact
                                            component={NotAuthorized}
                                        />
                                        <PrivateRoute
                                            path={getAppURIPath('page-render-error')}
                                            exact
                                            component={PageRenderError}
                                        />

                                        {/* IMS Routes */}
                                        {isStageEnvironment() && (
                                            <PrivateRoute path="/cc/oxford/ims-client" exact component={ImsClient} />
                                        )}

                                        <Redirect exact from="/" to="/taas" />

                                        <NonPrivateRoute component={NotFound} />
                                    </Switch>
                                </Suspense>
                            </div>
                        </main>
                    </ErrorBoundary>
                </V2Provider>
            </Provider>
        </Router>
    );
}

export default App;
