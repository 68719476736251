import { types, Instance } from 'mobx-state-tree';

export const ContentModel = types.model('ContentModel', {
    id: types.string,
    contentId: types.maybe(types.optional(types.string, '')),
    title: types.optional(types.string, ''),
    app: types.maybe(types.optional(types.string, '')),
    contentType: types.maybe(types.optional(types.string, '')),
    reviewedBy: types.maybe(types.optional(types.string, '')),
    status: types.maybe(types.optional(types.string, '')),
    modifiedDate: types.maybe(types.optional(types.union(types.literal('Yes'), types.literal('No')), 'No')),
});

export type IContentModel = Instance<typeof ContentModel>;
