const OXFORD_ORIGIN_PATH = '/cc/oxford/';
const RouteMapping = {
    'Oxford Curator': OXFORD_ORIGIN_PATH,
    'Oxford Admin': 'content-manager',
    500: 'something-went-wrong',
    404: 'not-found',
    401: 'not-authorized',
}

export default RouteMapping;
