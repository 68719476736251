import { AdobeIMS } from '@identity/imslib';
import ImsActions from './ims-actions';
import ImsData from './ims-data';

export default class IMS {
    imsActions: ImsActions;

    adobeIMS;

    imsEvents = null;

    constructor(onStateChanged?) {
        const imsData = new ImsData(onStateChanged, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            locale: 'en_US',
            scope: 'AdobeID,openid',
            environment: process.env.REACT_APP_ENV,
            key: 'adobeIMS',
            logsEnabled: true,
            redirect_uri: window.location.href,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.adobeIMS = new AdobeIMS(imsData.adobeIdData);
        this.adobeIMS.disableLogging();
        this.imsActions = new ImsActions(this.adobeIMS, imsData);
        this.adobeIMS.initialize();
    }

    isLoggedIn = () => {
        return this.imsActions.isSignedInUser();
    };

    login = () => {
        return this.imsActions.signIn();
    };

    getAccessToken = () => {
        return this.imsActions.getAccessToken();
    };

    logout = () => {
        return this.imsActions.signOut();
    };

    getProfile = async() => {
        return await this.imsActions.getProfile();
    }

    avatarUrl = (userId) => {
        return this.imsActions.avatarUrl(userId);
    }
}
