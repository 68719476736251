import createStore from '../../../stores/ContentManager/createStore';
import RouteMapping from './RouteConfig';
import { SESSION_STORAGE_USER_ROLE, USER_ROLE_ADMIN } from '../../../constants';

const store = createStore();
class RoleHelper {

     async getRoute(accessToken: string) {
        try{
            let response = await store.getUserRole(accessToken);
            if(!response.failed && response.data) {
                const { role } = response.data;
                if(role.length > 0) {
                    sessionStorage.setItem(SESSION_STORAGE_USER_ROLE,  role.join(', '));
                    const currentRole = role.includes(USER_ROLE_ADMIN) ? USER_ROLE_ADMIN : role[0];
                    return {failed: false, data: RouteMapping[currentRole]};
                }
            } else {
                throw response.status;
            }             
        } catch(ex) {
            return {failed:true, data: RouteMapping[ex]}
        }      
    }
};

export default new RoleHelper();