import { IRootStoreModel, RootStoreModel } from './models/RootStoreModel';

let rootStore: any;
const createStore = (): IRootStoreModel => {
    if (!rootStore) {
        rootStore = RootStoreModel.create();
    }
    return rootStore;
};

export default createStore;
