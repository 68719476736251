import { types, Instance, flow } from 'mobx-state-tree';
import { BASE_ERROR_MESSAGE } from '../../../constants';
import OxfordService from '../../../services/OxfordService';
import { ContentModel } from './ContentModel';
import { StatusModel } from './StatusModel';
import { parseAdminContent } from '../../../utils';
import config from '../../../components/contentManager/admin/utils/config.json';
import { BaseModel } from '../../Common/models/BaseModel';
import { logData } from '../../../services/utils/logger/logData';
import { SyncModel } from './SyncModel';

const oxfordService = new OxfordService();

export const AdminModel = types
    .model('AdminModel', {
        contents: types.optional(types.array(ContentModel), []),
        statuses: types.optional(types.array(StatusModel), []),
        total: types.optional(types.number, 0),
        showDefaultErrorToast: types.optional(types.boolean, false),
        lastSync: types.maybe(SyncModel),
    })
    .views((self) => ({
        get statusTotal() {
            let total = 0;

            try {
                if (Object.keys(self.statuses).length > 0) {
                    const statuses = JSON.parse(JSON.stringify(self.statuses));
                    for (let status of statuses) {
                        if (status.id !== 'READY-FOR-TAGGING') total += status.count;
                    }
                }
            } catch (error) {
                // TODO: ADD ERROR LOGGING
                logData({ request: {}, response: error, type: 'AdminModel>statusTotal', failed: true });
                console.error(`${BASE_ERROR_MESSAGE} > statusTotal`, error?.message);
                return total;
            }

            return total;
        },
    }))
    .actions((self) => {
        let initialState = {};
        return {
            // eslint-disable-next-line consistent-return
            fetchAdminContent: flow(function* fetchAdminContent() {
                let response: any;
                try {
                    /**
                     * Todo: Change to new API endpoint once available
                     */
                    response = yield oxfordService.fetchAdminContent();
                    console.log('[[adminResponse]]', response)
                    if (!response.failed && response.data) {
                        const { data } = response.data;
                        const { contents, summary } = data;

                        self.contents.replace(parseAdminContent(Object.keys(config.fieldMappings), contents));

                        if (summary && Object.keys(summary).length > 0) {
                            const { status } = summary;
                            if (status?.length > 0) {
                                self.statuses = summary?.status;
                            }
                        }
                    } else {
                        // Return error on Service Failure
                        throw response.data;
                    }
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({ request: {}, response: error, type: 'AdminModel>fetchAdminContent', failed: true });
                    console.error(`${BASE_ERROR_MESSAGE} > fetchAdminContent > ${response?.data?.status}`, error);
                    return { failed: true, status: response?.data?.status || 500 };
                }
            }),

            syncAllContents: flow(function* syncAllContents() {
                try {
                    let usersSyncResponse: any = yield oxfordService.usersSync();

                    if (usersSyncResponse.failed) {
                        // Return error on Service Failure
                        // TODO: ADD ERROR LOGGING
                        logData({
                            request: {},
                            response: usersSyncResponse?.data,
                            type: 'AdminModel>syncAllContents>userSync',
                            failed: true,
                        });
                        console.error(
                            `${BASE_ERROR_MESSAGE} > syncAllContents > userSync > ${usersSyncResponse?.data?.status}`,
                            usersSyncResponse.data,
                        );
                        return { failed: true, status: usersSyncResponse?.data?.status || 500 };
                    } else {
                        let contentSyncResponse: any = yield oxfordService.contentsSync();

                    if (contentSyncResponse.failed) {
                        // Return error on Service Failure
                        // TODO: ADD ERROR LOGGING
                        logData({
                            request: {},
                            response: usersSyncResponse?.data,
                            type: 'AdminModel>syncAllContents>contentsSync',
                            failed: true,
                        });
                        console.error(
                            `${BASE_ERROR_MESSAGE} > syncAllContents > contentSync > ${contentSyncResponse?.data?.status}`,
                            contentSyncResponse.data,
                        );
                        return { failed: true, status: contentSyncResponse?.data?.status || 500 };
                        }
                    }
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({
                        request: {},
                        response: error,
                        type: 'AdminModel>syncAllContents>syncAllContents',
                        failed: true,
                    });
                    console.error(`${BASE_ERROR_MESSAGE} AdminModel > syncAllContents > 500`, error);
                    return { failed: true, status: 500 };
                }
            }),

            resetContent: flow(function* resetContent() {
                try {
                    let response: any = yield oxfordService.resetContent();
                    logData({ request: {}, response, type: 'AdminModel>resetContent', failed: false });
                    if (!response.failed && response.data) {
                        const { data } = response.data;
                    } else {
                        // Return error on Service Failure
                        throw response.data;
                    }
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({ request: {}, response: error, type: 'AdminModel>resetContent', failed: true });
                    console.error(`${BASE_ERROR_MESSAGE} AdminModel > resetContent > 500`, error);
                    return { failed: true, status: 500 };
                }
            }),
            syncUsers: flow(function* syncUsers() {
                try {
                    const usersSyncResponse: any = yield oxfordService.usersSync();

                    if (usersSyncResponse.error || usersSyncResponse.failed) {
                        // Return error on Service Failure
                        // TODO: ADD ERROR LOGGING
                        logData({
                            request: {},
                            response: usersSyncResponse?.data,
                            type: 'AdminModel>syncAllContents>userSync',
                            failed: true,
                        });
                        console.error(
                            `${BASE_ERROR_MESSAGE} > syncAllContents > userSync > ${usersSyncResponse?.data?.status}`,
                            usersSyncResponse.data,
                        );
                        self.lastSync = { lastSyncDate: Date.now(), lastSyncSuccess: false };
                        return { failed: true, status: usersSyncResponse?.data?.status || 500 };
                    }
                    self.lastSync = { lastSyncDate: Date.now(), lastSyncSuccess: true };
                    return { failed: false, data: usersSyncResponse.data };
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({
                        request: {},
                        response: error,
                        type: 'AdminModel>syncAllContents>syncAllContents',
                        failed: true,
                    });
                    console.error(`${BASE_ERROR_MESSAGE} AdminModel > syncAllContents > 500`, error);
                    return { failed: true, status: 500 };
                }
            }),
        };
    });

export const RootStoreModel = types
    .compose(BaseModel, AdminModel)
    // ..and give it a nice name
    .named('RootStoreModel');

export type IRootStoreModel = Instance<typeof RootStoreModel>;
