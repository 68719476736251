import { IOption } from '../stores/TagManager/models/QuestionModel';
import { IContentModel } from '../stores/ContentManager/models/ContentModel';
import {
    APP_ROOT_URI,
    LOCAL_STORAGE_OID_KEY,
    PROD_ENV,
    SESSION_STORAGE_ACCEPT_ML_ALERT_KEY,
    SESSION_STORAGE_DISABLE_REMOVE_TAG_DLG,
    SESSION_STORAGE_DISABLE_RESET_DLG,
    SESSION_STORAGE_DISABLE_SAVE_DLG,
    SESSION_STORAGE_SHOW_ML_ALERT_KEY,
    SESSION_STORAGE_USER_ROLE,
    STAGE_ENV,
} from '../constants';

export const generateTagObjects = (allOptions, allTags, mlTags) => {
    const keys = Object.keys(allOptions);
    if (keys.length > 0) {
        keys.forEach((key) => {
            const option = allOptions[key];
            if (option) {
                if (option.isMLSuggested) {
                    mlTags.push(key);
                }
                allTags.push(key);
            }
        });
    }
};

export const parseSubmisson = (selectedTags, mlTags) => {
    let flattenedSelected = [];
    Object.keys(selectedTags).forEach((namespace) => {
        flattenedSelected = flattenedSelected.concat(selectedTags[namespace]);
    });
    const approved = mlTags.filter((x) => flattenedSelected.includes(x));
    const removed = mlTags.filter((x) => !flattenedSelected.includes(x));
    const added = flattenedSelected.filter((x) => !mlTags.includes(x));
    return {
        approvedTags: approved,
        removedTags: removed,
        addedTags: added,
    };
};

export const parseQuestions = (questions) => {
    if (questions.length > 0) {
        return questions.map((question, index) => {
            const allTags: IOption[] = [];
            const mlTags: IOption[] = [];
            const { allOptions } = question;
            generateTagObjects(allOptions, allTags, mlTags);
            return {
                id: question.id,
                isMultiSelect: question.isMultiSelect || false,
                allOptions: allOptions || {},
                nextQuestionIndex: question.nextQuestionIndex,
                options: question.options,
                allTags,
                mlTags,
                shortText: question.shortText,
                maxLimit: -1,
                defaultTags: question.defaultTags,
            };
        });
    }
    return [];
};

export const parseAdminContent = (headers, data) => {
    const parsedData: IContentModel[] = [];
    if (data.length > 0 && headers.length > 0) {
        data.forEach((row) => {
            const newRow = {};
            headers.forEach((header) => {
                const colName = header;
                if (row[colName]) {
                    if (colName === 'app') {
                        newRow['app'] = row['appSGCode'];
                        return;
                    }
                    if (colName === 'modifiedDate') {
                        newRow[colName] = !(
                            typeof row[colName] === 'undefined' ||
                            !row[colName] ||
                            row[colName].length === 0
                        )
                            ? 'Yes'
                            : 'No';
                        return;
                    }
                    newRow[colName] = row[colName];
                }
            });
            parsedData.push(newRow as IContentModel);
        });
    }
    return parsedData;
};

export const getAppURIPath = (pathName: string): string => {
    return `${APP_ROOT_URI}/${pathName}`;
};

export const getOIDList = (skipFlag, cid) => {
    let oidList: string[] = [];
    if (skipFlag) {
        if (!localStorage.getItem(LOCAL_STORAGE_OID_KEY)) {
            oidList = [cid];
            localStorage.setItem(LOCAL_STORAGE_OID_KEY, JSON.stringify(oidList));
        } else {
            oidList = JSON.parse(localStorage.getItem(LOCAL_STORAGE_OID_KEY) || 'null');
            if (oidList != null && cid) {
                oidList.push(cid);
                localStorage.setItem(LOCAL_STORAGE_OID_KEY, JSON.stringify(oidList));
            }
        }
    } else {
        oidList = JSON.parse(localStorage.getItem(LOCAL_STORAGE_OID_KEY) || 'null');
    }

    return oidList || [];
};

export const calculateSum = (items, prop) => {
    return items.reduce(function (a, b) {
        return a + b[prop];
    }, 0);
};

export const isDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
};

export const isStageEnvironment = () => {
    return process.env.REACT_APP_ENV === STAGE_ENV;
};

export const isProd = () => {
    return process.env.NODE_ENV === PROD_ENV;
};

export const removeStorageEntries = () => {
    // Remove oids key from localstorage which is storing Skip content
    localStorage.removeItem(LOCAL_STORAGE_OID_KEY);
    // Remove MLTag Alert Selection
    sessionStorage.removeItem(SESSION_STORAGE_ACCEPT_ML_ALERT_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_SHOW_ML_ALERT_KEY);
    // Remove User Role
    sessionStorage.removeItem(SESSION_STORAGE_USER_ROLE);
    // Remove Curator Dialog Settings
    sessionStorage.removeItem(SESSION_STORAGE_DISABLE_SAVE_DLG);
    sessionStorage.removeItem(SESSION_STORAGE_DISABLE_RESET_DLG);
    sessionStorage.removeItem(SESSION_STORAGE_DISABLE_REMOVE_TAG_DLG);
};

export const compareAscending = (a, b) => (a == b ? 0 : a < b ? -1 : 1);

/**
 * sortTagsByAlphaAscending - Sort Tags in ascending lexical order
 * @param tags
 * @returns
 */
export const sortTagsByAlphaAscending = (tags) => {
    if (tags.length > 0) {
        return tags.sort((tagA, tagB) => compareAscending(tagA.text.toUpperCase(), tagB.text.toUpperCase()));
    }
    return tags;
};

export const capitalizeFirstLetter = (str?: string) => {
    if (str && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};

/**
 * Removes the beginning of a string to limit it to {max} length and then moves the start of the string to the first alphanumerical letter of the next word.
 * @param str
 * @param max
 * @param offset
 * @param prefix
 * @param suffix
 */
export const truncate = (str: string, max: number, offset = 0, prefix = '...', suffix = '') => {
    if (str.length <= max) {
        return str;
    }
    let truncatedString = str.substr(offset + (str.length - max), str.length);
    truncatedString = truncatedString.substring(truncatedString.indexOf(' ') + 1);
    const firstAlphaLetter = (truncatedString.match(/[a-zA-Z]/) || []).pop();
    truncatedString = truncatedString.substring(firstAlphaLetter ? truncatedString.indexOf(firstAlphaLetter) : 0);
    return `${prefix} ${truncatedString}${suffix}`;
};

export const sortArrayByProperty = (array, property: string, order: string = 'asc') => {
    if (order === 'desc') {
        return array.sort((a, b) => (a[property] > b[property] ? -1 : 1));
    }
    return array.sort((a, b) => (a[property] < b[property] ? -1 : 1));
};
