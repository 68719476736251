import axios from 'axios';

const scrubError = (error) => {    
    if(error.config){
        const  { data , params, url, timeout} = error.config;
        error.config = {
            data, 
            params, 
            url, 
            timeout
        }
    }
    return error;
}

const request = async (
    url: string,
    headers: any,
    errorMessage: string,
    params?: any,
    method?: any,
    data?: any,
    timeout?: number,
) => {
    try {
        const config = {
            url,
            method: method || 'GET',
            headers: headers || {},
            data,
            params: params || {},
            timeout: timeout || 6000,
        };
        const response = await axios(config);
        if (response && response.data) {
            return { data: response.data, failed: false };
        }
        return { data: '', failed: true };
    } catch (error: any) {
        // TODO: ADD ERROR LOGGING
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return { data: scrubError(error.response), failed: true };
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return { data: scrubError(error.request), failed: true };
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.error('Error', error.message, error:any);
            return { data: scrubError(error), failed: true };
        }
    }
};

export default request;
