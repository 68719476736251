import { Button } from '@adobe/react-spectrum';
import React from 'react';
import Error from '../../common/Error';
import CloudDisconnected from '../../common/icons/CloudDisconnected'

const PageRenderError = () => {
    return (
        <Error
            refresh
            icon={<CloudDisconnected />}
            title="Couldn't load interface"
            descPrimary="The server encountered an error or isn’t available right now."
            descSecondary=" Try refreshing this page in a little while."
        />
    );
};

export default PageRenderError;
