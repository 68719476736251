import React from 'react';
import IMS from '../../ims';
import { removeStorageEntries } from '../../utils';
const OXFORD_ORIGIN_PATH = 'OXFORD::ORIGINPATH';
import RouteMapping from './utils/RouteConfig';
import RoleHelper from './utils/RoleHelper';
import {
    LOCAL_STORAGE_OID_KEY,
    SESSION_STORAGE_ACCEPT_ML_ALERT_KEY,
    SESSION_STORAGE_SHOW_ML_ALERT_KEY,
    SESSION_STORAGE_USER_ROLE
} from '../../constants';
import {logData} from '../../services/utils/logger/logData';


/**
 * Dummy redirect component.
 * todo: refactor better way later
 */
export default class LoginRedirect extends React.Component<{ location: any; history: any }> {
    async componentDidMount() {
        const { location, history } = this.props;
        try {
            const ims = new IMS();
            if (!ims.isLoggedIn()) {
                // short-tem solution. todo: create programmatic router component
                if (location.state && location.state.originPath) {
                    localStorage.setItem(OXFORD_ORIGIN_PATH, location.state.originPath);
                }
                removeStorageEntries();
                new IMS().login();
            } else {
                const response = await RoleHelper.getRoute(ims.getAccessToken());
                logData({ request: {}, response, type: 'Login', failed: false });
                if (response?.data) {
                    return history.push(response.data);
                } else {
                    throw new Error('Login failed');
                }
            }
        } catch (ex) {
            // TODO: ADD ERROR LOGGING
            logData({ request: {}, response: ex, type: 'Login error', failed: true });
            console.log('Login error', ex);
            return history.push(RouteMapping[500]);
        }
    }

    render() {
        return null;
    }
}
