import request from '../../utils';
import IMS from '../../../ims';
import moment from 'moment';
import { BACKEND_ERROR_MESSAGE } from '../../../constants';

/**
 * Get operating system method..
 */
const getOperatingSystem = (window) => {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
        operatingSystem = 'Windows OS';
    }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) {
        operatingSystem = 'MacOS';
    }
    if (window.navigator.appVersion.indexOf('X11') !== -1) {
        operatingSystem = 'UNIX OS';
    }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) {
        operatingSystem = 'Linux OS';
    }
    return operatingSystem;
};

/**
 * Get Data pushing to Dunamis..
 */
const dataPushtoAnalytics = async (data: object) => {
    try {
        const imsInstance = new IMS();
        const token = await imsInstance.getAccessToken();
        const url = `${process.env.DUNAMIS_URL}`;
        const apiKey =
            getOperatingSystem(window) === 'Windows OS'
                ? `${process.env.DUNAMIS_API_KEY_WIN}`
                : `${process.env.DUNAMIS_API_KEY_MAC}`;
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            'x-api-key': apiKey,
        };
        //console.log('Dunamis request obj========>', data);
        const response = await request(url, headers, BACKEND_ERROR_MESSAGE, {}, 'POST', data);
        //console.log('Dunamis response========>', data);
        return response;
    } catch (error) {
        console.error(`Failed to retrieve data: (${error})`);
    }
};

/**
 * Get Data logging and formatting to match with Dunamis..
 */
export const logData = async (dataObj: object) => {
    const imsInstance = new IMS();
    const userProfile: any = await imsInstance.getProfile();
    let userId = '';
    if (!userProfile['failed'] && userProfile.data) {
        userId = userProfile.data.userId;
    }
    let project = 'oxford-mac-service';
    let OS = getOperatingSystem(window) === 'Windows OS' ?? 'MacOS';
    let host = 'webapp';
    let finalObj = {
        project,
        environment: `${process.env.NODE_ENV}`,
        time: `${moment().format('YYYY-MM-DDTHH:mm:ss.SSS').toString()}Z`,
        ingesttype: 'dunamis',
        data: {
            'event.workflow': 'oxford',
            'event.category': 'DESKTOP',
            'event.subcategory': '',
            'event.type': dataObj['type'] ?? '',
            'event.subtype': '',
            'event.user_guid': userId,
            'event.dts_end': `${moment().format('YYYY-MM-DDTHH:mm:ss.SSS').toString()}Z`,
            'event.user_agent': navigator.userAgent,
            'event.language': 'en_US',
            'env.com.name': 'oxford',
            'env.com.version': `${process.env.APP_VERSION}` ?? '1.0',
            'source.name': `${host}`,
            'source.version': ``,
            'source.device': OS,
            'source.os_version': '',
            'content.status': dataObj['failed'],
            'event.value': { request: dataObj['request'], response: dataObj['response'] },
        },
    };
    let finalContent = {
        events: [],
    };
    let events: any = [];
    events = [...finalContent['events'], JSON.parse(JSON.stringify(finalObj))];
    finalContent['events'] = events;
    return dataPushtoAnalytics(finalContent);
};
