import SelectAdd from '@spectrum-icons/workflow/SelectAdd';
import { Instance, types, flow } from 'mobx-state-tree';
import IMS from '../../../ims';
import { removeStorageEntries } from '../../../utils';
import { BASE_ERROR_MESSAGE, SESSION_STORAGE_USER_ROLE, LOCAL_STORAGE_OID_KEY } from '../../../constants';
import OxfordService from '../../../services/OxfordService';
const oxfordService = new OxfordService();

import { logData } from '../../../services/utils/logger/logData';
export const BaseModel = types
    .model('BaseModel', {
        displayName: types.optional(types.string, ''),
        userEmail: types.optional(types.string, ''),
        userRole: types.optional(types.string, ''),
        userAvatarUrl: types.optional(types.string, ''),
    })
    .actions((self) => {
        return {
            endSession() {
                removeStorageEntries();
                new IMS().logout();
            },
            getUserRole: flow(function* getProfile(token?: string) {
                let response;
                try {
                    response = yield oxfordService.fetchUserInfo(token);
                    if (!response.failed && response.data) {
                        const { role } = response.data;
                        logData({ request: {}, response: response, type: 'BaseModel>getUserRole', failed: false });
                        return { failed: false, data: { role } };
                    } else {
                        // Return error on Service Failure
                        throw response.data;
                    }
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({ request: {}, response: error, type: 'BaseModel>getUserRole', failed: true });
                    console.error(`${BASE_ERROR_MESSAGE} > BaseModel > getUserRole > ${response?.data?.status}`, error?.message);
                    return { failed: true, status: response?.data?.status || 500 };
                }
            }),
        };
    })
    .actions((self) => {
        return {
            getUserProfile: flow(function* getProfile() {
                let response;
                try {
                    const imsInstance = new IMS();
                    response = yield imsInstance.getProfile();

                    if (!response.failed && response.data) {
                        const { displayName, email, userId } = response.data;
                        logData({
                            request: {},
                            response: { userId, message: 'Success: Received user info' },
                            type: 'BaseModel>getUserProfile',
                            failed: false,
                        });
                        if (displayName) {
                            self.displayName = displayName;
                        }

                        if (email) {
                            self.userEmail = email;
                        }

                        if (userId) {
                            const avatarurl = imsInstance.avatarUrl(userId);
                            self.userAvatarUrl = imsInstance.avatarUrl(userId);
                        }

                        if(!sessionStorage.getItem(SESSION_STORAGE_USER_ROLE)){
                            const userInfoResponse = yield self.getUserRole();

                            if (!userInfoResponse.failed && userInfoResponse.data) {
                                const { role } = userInfoResponse.data;
                                if (role && role.length > 0) {
                                    self.userRole = role.join(', ');
                                }
                            }
                           
                        } else {
                             self.userRole = sessionStorage.getItem(SESSION_STORAGE_USER_ROLE) || '';
                        }
                      
                    } else {
                        // Return error on Service Failure
                        throw response.data;
                    }
                } catch (error) {
                    // TODO: ADD ERROR LOGGING
                    logData({ request: {}, response: error, type: 'BaseModel>getUserProfile', failed: true });
                    console.error(`${BASE_ERROR_MESSAGE} > BaseModel > getUserProfile > ${response?.data?.status}`, error?.message);
                    return { failed: true, status: response?.data?.status || 500 };
                }
            }),
        };
    });

export type IBaseModel = Instance<typeof BaseModel>;
