export const BASE_ERROR_MESSAGE = 'OXFORD TOOL';
export const BACKEND_ERROR_MESSAGE = 'OXFORD SERVICE FAILURE';
export const DEFAULT_LOCALE = 'en_US';
export const APP_ROOT_URI = '/cc/oxford';
export const TAGGER_VIEW = 'tag';
export const CURATE_VIEW = 'curate';
export const USER_PROFILE_VIEW = 'user-profile';
export const AUTH_ERROR_CODE = 401;
//local storage keys
export const LOCAL_STORAGE_OID_KEY = 'oidlist';
export const LOCAL_STORAGE_ARTICLE_LOAD_TIME_KEY = 'article-load-time';
export const SESSION_STORAGE_SHOW_ML_ALERT_KEY = 'show-ml-alert';
export const SESSION_STORAGE_ACCEPT_ML_ALERT_KEY = 'mltag-alert-accept';
//session storage keys
export const SESSION_STORAGE_USER_ROLE = 'user-role';
export const SESSION_STORAGE_DISABLE_SAVE_DLG = 'disable-save-dlg';
export const SESSION_STORAGE_DISABLE_REMOVE_TAG_DLG = 'disable-rmv-tag-dlg';
export const SESSION_STORAGE_DISABLE_RESET_DLG = 'disable-save-dlg';
//environments
export const STAGE_ENV = 'stg1';
export const PRE_PROD_ENV = 'preprod';
export const PROD_ENV = 'prod';

//MLTagsShownPerCategory
export const ML_TAGS_SHOWN_PER_CATEGORY = 5;

export const MAX_TAG_LENGTH = 55;
export const USER_ROLE_ADMIN = 'Oxford Admin';
