import request from './utils';
import { BACKEND_ERROR_MESSAGE } from '../constants';
import IMS from '../ims';
import { logData } from './utils/logger/logData';

const BASE_API_URL = process.env.OXFORD_API_ENDPOINT
    ? process.env.OXFORD_API_ENDPOINT
    : 'https://oxford-dev.adobe.io/oxford';
const API_KEY = 'OxfordTool1';
class OxfordService {
    /**
     * Oxford service request headers..
     */
    getRequestHeaders = () => ({
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: '',
    });

    getBaseHeaders = async () => {
        const token = await new IMS().getAccessToken();
        return {
            ...this.getRequestHeaders(),
            authorization: `Bearer ${token}`,
        };
    };

    // Tagger Methods
    fetchArticle = async (skipContent, view) => {
        const loggerData = {
            request: { skipContent },
            response: {},
            type: 'OxfordService>Tagger>fetchArticle',
            failed: false,
        };
        try {
            const url = `${BASE_API_URL}/content`;
            const headers = await this.getBaseHeaders();
            const params = {
                view,
            };
            const data = { skipContent };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, params, 'POST', data, 10000);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > fetchArticle > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    fetchAllTags = async (product, version, locale) => {
        const loggerData = {
            request: { product, version, locale },
            response: {},
            type: 'OxfordService>Admin>fetchAllTags',
            failed: false,
        };
        try {
            const url = `${BASE_API_URL}tool/tags`;
            const headers = await this.getBaseHeaders();
            const params = {
                product,
                version,
                locale,
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, params);
            // logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > fetchAllTags > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    postArticleTags = async (view: string, cid: string, data: any) => {
        const loggerData = { request: data, response: {}, type: 'OxfordService>Tagger>postArticleTags', failed: false };
        try {
            const url = `${BASE_API_URL}/content/tags`;
            const headers = await this.getBaseHeaders();
            const params = {
                oxfordId: cid,
                view,
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, params, 'POST', data);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > postArticleTags > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    // Admin Methods
    fetchAdminContent = async () => {
        const loggerData = { request: {}, response: {}, type: 'OxfordService>Admin>fetchAdminContent', failed: false };
        try {
            const url = `${BASE_API_URL}/contents`;
            const headers = await this.getBaseHeaders();
            const params = {
                //view,
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE);
           // logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > fetchAdminContent > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    contentsSync = async () => {
        const loggerData = { request: { operation: 'sync' }, response: {}, type: 'OxfordService>Admin>contentsSync', failed: false };
        try {
            const url = `${BASE_API_URL}/contents`;
            const headers = await this.getBaseHeaders();
            const data = {
                operation: 'sync',
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, {}, 'POST', data, 10000);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > contentsSync > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    usersSync = async () => {
        const loggerData = { request: {}, response: {}, type: 'OxfordService>Admin>usersSync', failed: false };
        try {
            const url = `${BASE_API_URL}/users`;
            const headers = await this.getBaseHeaders();
            const data = {
                operation: 'sync',
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, {}, 'POST', data);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > usersSync > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    resetContent = async () => {
        const loggerData = { request: {}, response: {}, type: 'OxfordService>Admin>resetContent', failed: false };
        try {
            const url = `${BASE_API_URL}/contents`;
            const headers = await this.getBaseHeaders();
            const data = {
                operation: 'delete',
            };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, {}, 'POST', data, 15000);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > resetContent > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    // Curator Methods
    fetchCuratorContent = async (skipContent, view) => {
        const loggerData = { request: { skipContent }, response: {}, type: 'OxfordService>Curator>fetchCuratorContent', failed: false };
        try {
            const url = `${BASE_API_URL}/content`;
            const headers = await this.getBaseHeaders();
            const params = {
                view,
            };
            const data = { skipContent };
            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, params, 'POST', data);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > fetchCuratorContent > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    saveCuratedTags = async (userIdx: string, cid: string, data: any) => {
        const loggerData = { request: {}, response: {}, type: 'OxfordService>Curator>saveCuratedTags', failed: false };
        try {
            const url = `${BASE_API_URL}/content/tags`;
            const headers = await this.getBaseHeaders();
            const params = {
                oxfordId: cid,
                userIdx,
                api_key: API_KEY,
            };

            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, params, 'POST', data);
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > saveCuratedTags > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };

    //Login Methods
    fetchUserInfo = async (token?: string) => {
        const loggerData = { request: {}, response: {}, type: 'OxfordService>Login>fetchUserInfo', failed: false };
        try {
            const url = `${BASE_API_URL}/user`;
            let headers = token ? await this.getRequestHeaders() : await this.getBaseHeaders();

            if (token) {
                headers = {
                    ...headers,
                    authorization: `Bearer ${token}`,
                };
            }

            const response = await request(url, headers, BACKEND_ERROR_MESSAGE, {});
            logData({ ...loggerData, response });
            return response;
        } catch (error: any) {
            // TODO: ADD ERROR LOGGING
            console.error(`${BACKEND_ERROR_MESSAGE} OxfordService > fetchUserInfo > ${error.message}`);
            logData({ ...loggerData, response: error, failed: true });
            return { data: error, failed: true };
        }
    };
}

export default OxfordService;

